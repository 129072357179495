/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import {
  VictoryChart,
  VictoryLine,
  VictoryBar,
  VictoryTooltip,
  VictoryVoronoiContainer,
  VictoryAxis,
  VictoryLabel,
  VictoryClipContainer,
} from "victory";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import Icons from "components/CustomIcons";
import colors from "assets/theme/base/colors";
import { timeHour, timeDay, timeMonth } from "d3-time";
import { timeFormat } from "d3-time-format";
import { connect } from "react-redux";
import { getTrendData, removeTrendData } from "store/actions/trendsActions";
import { useTranslation } from "react-i18next";
import { useMaterialUIController } from "context";
import moment from "moment";
import { Grid, Modal } from "@mui/material";
import AlertDialog from "components/AlertDialog";
import DownloadForm from "./downloadForm";
import SettingsForm from "./settingsForm";

const zeroPad = (num) => String(num).padStart(2, "0");

function CustomLabel(props) {
  const { type, decimalPlaces, sufix, getTimeFormat } = props;
  const { x, datum } = props;
  const { t } = useTranslation();
  const typeLabel = [t("time"), t("day"), t("month")];
  const initialX = x;
  const initialY = 10;
  return (
    <>
      <text x={initialX} y={initialY} fontSize={16} textAnchor="middle">
        {`${typeLabel[type]}: ${getTimeFormat()(datum?.x)}`}
      </text>
      <text x={initialX} y={initialY + 20} fontSize={16} textAnchor="middle">
        {`${parseFloat(datum?.y).toFixed(decimalPlaces)} ${sufix}`}
      </text>
    </>
  );
}

function CustomLine(props) {
  const { x, height } = props;
  return (
    <g>
      <line
        style={{ opacity: 0.5 }}
        transform={`translate(${x}, 50)`}
        x1={0}
        y1={height - 100}
        stroke="grey"
        strokeWidth={2}
      />
    </g>
  );
}

function Chart(props) {
  const { t } = useTranslation();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const ref = useRef(null);

  const date = new Date();
  const {
    getTrendDataServer,
    trendData,
    trend1,
    trend2,
    devicetype,
    device,
    trendsHistory,
    actRemoveTrendData,
  } = props;
  const [yLabelSize, setYLabelSize] = useState(50);
  const [showChart, setShowChart] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [openDownload, setOpenDownload] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [type, setType] = useState(0);
  const [lastDay, setLastDay] = useState(date.getDate());
  const [lastMonth, setLastMonth] = useState(date.getMonth());
  const [formatedData, setFormatedData] = useState([]);
  const [dateInterval, setDateInterval] = useState({
    startDate: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
    endDate: new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1, 0, 10),
  });
  const [historyData, setHistoryData] = useState({
    lastDay: 0,
    lastMonth: 0,
    lastValue: 0,
  });

  useEffect(() => {
    const { valueAddress, energyAddress, consumptionControlType } = device;

    if (consumptionControlType === "ELECTRICENERGY") {
      setHistoryData({
        lastDay: trendsHistory[energyAddress]?.lastDay || 0,
        lastMonth: trendsHistory[energyAddress]?.lastMonth || 0,
        lastValue: trendsHistory[energyAddress]?.lastValue || 0,
        address: energyAddress,
      });
    } else {
      setHistoryData({
        lastDay: trendsHistory[valueAddress]?.lastDay || 0,
        lastMonth: trendsHistory[valueAddress]?.lastMonth || 0,
        lastValue: trendsHistory[valueAddress]?.lastValue || 0,
        address: valueAddress,
      });
    }
  }, []);

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.clientHeight);
      setWidth(ref.current.clientWidth);
    }
  });

  const address = type !== 0 && Object.keys(trend2).length > 0 ? trend2?.address : trend1?.address;
  const name = type !== 0 && Object.keys(trend2).length > 0 ? trend2?.name : trend1?.name;
  const decimalPlaces =
    type !== 0 && Object.keys(trend2).length > 0 ? trend2?.decimalPlaces : trend1?.decimalPlaces;
  const sufix = type !== 0 && Object.keys(trend2).length > 0 ? trend2?.sufix : trend1?.sufix;
  const color = type !== 0 && Object.keys(trend2).length > 0 ? trend2?.color : trend1?.color;

  console.log(trend1, trend2);

  useEffect(() => {
    const typeText = ["DAY", "MONTH", "YEAR"];
    const { startDate } = dateInterval;
    if (address)
      getTrendDataServer({
        address,
        date: `${startDate.getFullYear()}-${zeroPad(startDate.getMonth() + 1)}-${zeroPad(
          startDate.getDate()
        )}`,
        type: typeText[type],
      });
  }, [dateInterval, type, getTrendDataServer, address, name, decimalPlaces, sufix, color]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setShowChart(devicetype !== "device6");
      setShowTable(devicetype === "device6");
    }, 100);
    return () => {
      setShowChart(false);
      setShowTable(false);
    };
  }, []);

  useEffect(() => {
    let yValueSize = 0;
    let newFormatedData = [];
    if (trendData) {
      // Auxiliar loop detect same values
      let firstValue;
      let sameValues = true;
      trendData.every(({ value }) => {
        if (!firstValue) {
          firstValue = value;
        }
        if (value !== firstValue) {
          sameValues = false;
          return false;
        }
        return true;
      });
      // end
      const filteredData = trendData.filter(({ timestamp, value }) => timestamp && value);
      newFormatedData = filteredData.map(({ timestamp, value }) => {
        let yValue = 0;
        if (sameValues) {
          yValue = parseFloat(value).toFixed(decimalPlaces);
        } else {
          yValue = parseFloat(parseFloat(value).toFixed(decimalPlaces));
        }
        const currentYValueSize = yValue.toString().length;
        if (currentYValueSize > yValueSize) yValueSize = currentYValueSize;
        return {
          x: new Date(parseInt(timestamp, 10)),
          y: yValue,
        };
      });
    }
    const newLabelSize = yValueSize * 8 + 15;
    if (newLabelSize < 50) {
      setYLabelSize(50);
    } else if (newLabelSize < 100) {
      setYLabelSize(newLabelSize);
    } else {
      setYLabelSize(100);
    }
    setFormatedData(newFormatedData);

    if (newFormatedData.length > 0) {
      const lastElement = newFormatedData[newFormatedData.length - 1];
      const momentLast = moment(lastElement.x.valueOf());
      if (moment().isSame(momentLast, "date")) {
        newFormatedData.push({ x: new Date(), y: lastElement.y });
      } else {
        const endOfDay = momentLast.endOf("day").toDate();
        newFormatedData.push({ x: endOfDay, y: lastElement.y });
      }
    }
  }, [trendData, decimalPlaces]);

  const getTitleTimeFormat = () => {
    switch (type) {
      case 0: {
        return timeFormat("%d/%m/%Y");
      }
      case 1: {
        return timeFormat("%m/%Y");
      }
      default: {
        return timeFormat("%Y");
      }
    }
  };

  const getTimeFormat = () => {
    switch (type) {
      case 0: {
        return timeFormat("%H:%M");
      }
      case 1: {
        return timeFormat("%d");
      }
      default: {
        return timeFormat("%m");
      }
    }
  };

  const getTimeRange = () => {
    switch (type) {
      case 0: {
        return timeHour.every(6).range(dateInterval.startDate, dateInterval.endDate);
      }
      case 1: {
        return timeDay.every(2).range(dateInterval.startDate, dateInterval.endDate);
      }
      default: {
        return timeMonth.every(1).range(dateInterval.startDate, dateInterval.endDate);
      }
    }
  };

  const moveInterval = (direction) => {
    const { startDate } = dateInterval;
    const [year, month, day] = [startDate.getFullYear(), startDate.getMonth(), startDate.getDate()];
    switch (type) {
      case 0: {
        const newDay = day + (direction === "up" ? 1 : -1);
        const newStartDate = new Date(year, month, newDay);
        setLastDay(newStartDate.getDate());
        const newEndDate = new Date(year, month, newDay + 1);
        setDateInterval({ startDate: newStartDate, endDate: newEndDate });
        break;
      }
      case 1: {
        const newMonth = month + (direction === "up" ? 1 : -1);
        const newStartDate = new Date(year, newMonth, 1);
        setLastMonth(newStartDate.getMonth());
        const newEndDate = new Date(year, newMonth + 1, 0);
        setDateInterval({ startDate: newStartDate, endDate: newEndDate });
        break;
      }
      case 2: {
        const newYear = year + (direction === "up" ? 1 : -1);
        const newStartDate = new Date(newYear, 0, 1);
        const newEndDate = new Date(newYear + 1, 0, 0);
        setDateInterval({ startDate: newStartDate, endDate: newEndDate });
        break;
      }
      default:
        break;
    }
  };

  const getTable = () => (
    <div style={{ display: "flex", justifyContent: "center", marginTop: 16 }}>
      <style>{`
        .table-container-chart {
            width: 75%;
            max-height: 300px;
            overflow-y: auto;
            margin: 0px 0;
        }
        .table-chart {
            border-collapse: collapse;
            width: 100%;
        }
        .table-chart th, td {
            font-size: 14px;
            border-bottom: 1px solid grey;
            padding: 4px;
            text-align: center;
        }
        .table-chart th {
            background-color: white;
            position: sticky;
            top: 0;
            z-index: 1;
        }
        .table-chart tr:last-child td {
            border-bottom: none;
        }
      `}</style>
      <div className="table-container-chart">
        <table className="table-chart">
          <thead>
            <tr>
              <th>{t("date")}</th>
              <th>{t("value")}</th>
            </tr>
          </thead>
          <tbody>
            {formatedData.map((row) => (
              <tr key={row.x.valueOf()}>
                <td style={{ width: "50%" }}>
                  {row.x.toLocaleString(undefined, { hourCycle: "h23" })}
                </td>
                <td style={{ width: "50%" }}>{row.y}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  const getChart = () => (
    <MDBox
      flexGrow={1}
      m={2}
      ref={(el) => {
        ref.current = el;
      }}
    >
      <VictoryChart
        responsive={false}
        animate={{
          duration: 400,
          onLoad: { duration: 400 },
        }}
        domain={{
          x: [dateInterval.startDate, dateInterval.endDate],
        }}
        domainPadding={type === 2 ? 20 : 10}
        padding={{ top: 50, left: yLabelSize, bottom: 50, right: 50 }}
        scale={{ y: "linear" }}
        height={height}
        width={width}
        containerComponent={
          <VictoryVoronoiContainer
            responsive={false}
            style={{ strokeWidth: 1 }}
            cursorDimension="x"
            voronoiDimension="x"
            labels={() => "ABC"}
            zoomDimension="x"
            labelComponent={<CustomLine height={height} />}
          />
        }
      >
        <VictoryAxis
          dependentAxis
          style={{
            ticks: { stroke: darkMode ? "white" : "grey", size: 5 },
            axis: { stroke: darkMode ? "white" : "grey", size: 5 },
            tickLabels: { fill: darkMode ? "white" : "grey" },
          }}
        />
        <VictoryAxis
          crossAxis={false}
          orientation="bottom"
          name="time-axis"
          tickFormat={getTimeFormat()}
          tickValues={getTimeRange()}
          tickLabelComponent={<VictoryLabel textAnchor="middle" />}
          style={{
            ticks: { stroke: darkMode ? "white" : "grey", size: 5 },
            axis: { stroke: darkMode ? "white" : "grey", size: 5 },
            tickLabels: { fill: darkMode ? "white" : "grey" },
          }}
        />
        {type === 0 && (
          <VictoryLine
            name="value"
            key="line"
            style={{
              data: { stroke: color, opacity: 0.7, strokeWidth: 4 },
            }}
            interpolation="linear"
            labels={() => "ABC"}
            data={formatedData}
            labelComponent={
              <VictoryTooltip
                cornerRadius={4}
                flyoutWidth={130}
                flyoutHeight={60}
                pointerLength={0}
                flyoutStyle={{
                  strokeWidth: 2,
                  fill: "white",
                  fillOpacity: 0.6,
                  pointerEvents: "none",
                  stroke: "#979797",
                }}
                center={{ y: 15 }}
                centerOffset={{ x: 0 }}
                labelComponent={
                  <CustomLabel
                    getTimeFormat={getTimeFormat}
                    sufix={sufix}
                    decimalPlaces={decimalPlaces}
                    type={type}
                  />
                }
              />
            }
          />
        )}
        {type !== 0 && (
          <VictoryBar
            name="value"
            key="bar"
            style={{
              data: { fill: color },
            }}
            barWidth={type === 1 ? 10 : 20}
            interpolation="linear"
            labels={() => {}}
            groupComponent={<VictoryClipContainer />}
            data={formatedData}
            labelComponent={
              <VictoryTooltip
                cornerRadius={4}
                flyoutWidth={130}
                flyoutHeight={60}
                pointerLength={0}
                flyoutStyle={{
                  strokeWidth: 2,
                  fill: "white",
                  fillOpacity: 0.6,
                  pointerEvents: "none",
                  stroke: darkMode ? "white" : "grey",
                }}
                center={{ y: 15 }}
                centerOffset={{ x: 0 }}
                labelComponent={
                  <CustomLabel
                    getTimeFormat={getTimeFormat}
                    sufix={sufix}
                    decimalPlaces={decimalPlaces}
                    type={type}
                  />
                }
              />
            }
          />
        )}
      </VictoryChart>
    </MDBox>
  );

  return (
    <Card sx={{ height: "90vh", overflow: "hidden" }}>
      <AlertDialog
        open={openConfirm}
        handleClose={() => {
          setOpenConfirm(false);
        }}
        handleAgree={() => {
          actRemoveTrendData({
            device,
            cb: (response) => {
              if (response) {
                setOpenConfirm(false);
              }
            },
          });
        }}
      />
      <Modal
        open={openDownload}
        onClose={() => {
          setOpenDownload(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "scroll" }}
      >
        <MDBox
          mt={3}
          sx={12}
          sm={8}
          style={{
            position: "absolute",
            width: "65vw",
            left: "50%",
            transform: "translate(-50%, 0%)",
          }}
        >
          <DownloadForm address={address} />
        </MDBox>
      </Modal>
      <Modal
        open={openSettings}
        onClose={() => {
          setOpenSettings(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "scroll" }}
      >
        <MDBox
          mt={3}
          sx={12}
          sm={8}
          style={{
            position: "absolute",
            width: "65vw",
            left: "50%",
            transform: "translate(-50%, 0%)",
          }}
        >
          <SettingsForm setOpenSettings={setOpenSettings} historyData={historyData} />
        </MDBox>
      </Modal>
      <MDBox p={2} display="flex" flexDirection="column" justifyContent="space-between">
        <MDBox display="flex" justifyContent="center">
          <MDButton
            color="info"
            style={{ margin: 10 }}
            onClick={() => {
              const { startDate } = dateInterval;
              const year = startDate.getFullYear();
              setDateInterval({
                startDate: new Date(year, lastMonth, lastDay),
                endDate: new Date(year, lastMonth, lastDay + 1, 0, 10),
              });
              setType(0);
            }}
          >
            <MDTypography color="white" textTransform="capitalize">
              {t("day").toUpperCase()}
            </MDTypography>
          </MDButton>
          <MDButton
            color="info"
            style={{ margin: 10 }}
            onClick={() => {
              const { startDate } = dateInterval;
              const year = startDate.getFullYear();
              setDateInterval({
                startDate: new Date(year, lastMonth, 1),
                endDate: new Date(year, lastMonth + 1, 1),
              });
              setType(1);
            }}
          >
            <MDTypography color="white" textTransform="capitalize">
              {t("month").toUpperCase()}
            </MDTypography>
          </MDButton>
          <MDButton
            color="info"
            style={{ margin: 10 }}
            onClick={() => {
              const { startDate } = dateInterval;
              const year = startDate.getFullYear();
              setDateInterval({
                startDate: new Date(year, 0, 1),
                endDate: new Date(year + 1, 0, 0),
              });
              setType(2);
            }}
          >
            <MDTypography color="white" textTransform="capitalize">
              {t("year").toUpperCase()}
            </MDTypography>
          </MDButton>
        </MDBox>
      </MDBox>
      <Grid container mb={2} justifyContent="center" alignItems="center">
        <Grid item display="flex" flexDirection="row">
          <MDBox
            m={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
            onClick={() => {
              moveInterval("down");
            }}
          >
            <Icons name="ChevronLeft" size={40} color={colors.info.main} />
          </MDBox>

          <MDBox>
            <MDTypography style={{ margin: 10 }} color="text" textTransform="capitalize">
              {getTitleTimeFormat()(dateInterval.startDate)}
            </MDTypography>
          </MDBox>
          <MDBox
            m={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
            onClick={() => {
              moveInterval("up");
            }}
          >
            <Icons name="ChevronRight" size={40} color={colors.info.main} />
          </MDBox>
        </Grid>
        <Grid item display="flex" flexDirection="row">
          <MDBox
            style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            onClick={() => {
              setShowChart(true);
              setShowTable(false);
            }}
          >
            <Icons name="Chart" size={40} color="grey" />
          </MDBox>
          <MDBox
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 16,
            }}
            onClick={() => {
              setShowChart(false);
              setShowTable(true);
            }}
          >
            <Icons name="List" size={40} color="grey" />
          </MDBox>
          <MDBox
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 16,
            }}
            onClick={() => {
              setOpenDownload(true);
            }}
          >
            <Icons name="Download" size={40} color="grey" />
          </MDBox>
          <MDBox
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 16,
            }}
            onClick={() => {
              setOpenSettings(true);
            }}
          >
            <Icons name="CogOutline" size={40} color="grey" />
          </MDBox>
          <MDBox
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 16,
            }}
            onClick={() => {
              setOpenConfirm(true);
            }}
          >
            <Icons name="TrashCanOutline" size={40} color="grey" />
          </MDBox>
        </Grid>
      </Grid>
      {showChart && getChart()}
      {showTable && getTable()}
    </Card>
  );
}

Chart.defaultProps = {
  trendData: [],
  trend1: {},
  trend2: {},
  devicetype: "",
};

Chart.propTypes = {
  getTrendDataServer: PropTypes.func.isRequired,
  actRemoveTrendData: PropTypes.func.isRequired,
  trendData: PropTypes.array,
  trend1: PropTypes.object,
  trend2: PropTypes.object,
  devicetype: PropTypes.string,
};

CustomLabel.defaultProps = {
  type: 0,
  decimalPlaces: "0",
  sufix: "",
};

CustomLabel.propTypes = {
  getTimeFormat: PropTypes.func.isRequired,
  type: PropTypes.number,
  decimalPlaces: PropTypes.string,
  sufix: PropTypes.string,
};

CustomLine.defaultProps = {
  x: 0,
  height: 0,
};

CustomLine.propTypes = {
  x: PropTypes.number,
  height: PropTypes.number,
};

const mapStateToProps = (state, props) => {
  const { device } = props;
  const {
    type,
    powerTrendNodeId,
    energyTrendNodeId,
    trendNodeId,
    trendIndex, // For devices 6 and 7
    consumptionControlType,
  } = device;
  const {
    trendsReducer: { trendData, trends, trendsHistory },
  } = state;
  const nodeId1 =
    consumptionControlType === "ELECTRICENERGY" ? powerTrendNodeId : trendNodeId || trendIndex;
  const nodeId2 = consumptionControlType === "ELECTRICENERGY" ? energyTrendNodeId : "";
  const trendsArray1 = trends.filter((trend) => trend.nodeId === nodeId1);
  const trendsArray2 = trends.filter((trend) => trend.nodeId === nodeId2);

  return {
    trendData,
    trend1: trendsArray1.length > 0 ? trendsArray1[0] : undefined,
    trend2: trendsArray2.length > 0 ? trendsArray2[0] : undefined,
    devicetype: type,
    trendsHistory,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getTrendDataServer: (data) => {
    dispatch(getTrendData(data));
  },
  actRemoveTrendData: (data) => {
    dispatch(removeTrendData(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Chart);
